.numberformat {
  -webkit-appearance: none;
  padding: 10px;
  border-radius: 10px;
  outline: none;
  border-width: 0px;
  letter-spacing: 2px;
  background-color: #ededed;
}

.numberformat:focus {
  box-shadow: 0 0 0 2px #9aeabc;
}

.numberformat .error {
  box-shadow: 0 0 0 2px red;
}

.numberformat .disabled {
  background-color: #e0e0e0;
  color: gray;
}
